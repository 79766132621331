/* THIS FILE HAS THE CONTENTS FROM global.js AND constants.js from the jQuery project */
import Cookies from "universal-cookie";
const cookies = new Cookies();

// html/js/global.js

/* All Configuration will be stored here  */

export const subdomain = getDomain(window.location.href, true);
/* AWS COGNITO CONFIG */
const cognito_mappings = {
    'user_pool_id': {
        'dev.looplearn.live': 'ap-southeast-2_mSfh75PRx',
        'dev2.looplearn.live': 'ap-southeast-2_mSfh75PRx',
        'dev3.looplearn.live': 'ap-southeast-2_mSfh75PRx',
        'beta.looplearn.live': 'ap-southeast-2_mSfh75PRx',
        'app.looplearn.live': 'ap-southeast-2_mSfh75PRx',
        'dev.loopsafe.live': 'ap-southeast-2_AmNmQndRY',
        'beta.loopsafe.live': 'ap-southeast-2_ripsMX8uP',
        'app.loopsafe.live': 'ap-southeast-2_vQ4EZ9SDC'
    },
    'client_id': {
        'dev.looplearn.live': '74ggjm4mbp4o75pn6sbasst72r',
        'dev2.looplearn.live': '74ggjm4mbp4o75pn6sbasst72r',
        'dev3.looplearn.live': '74ggjm4mbp4o75pn6sbasst72r',
        'beta.looplearn.live': '74ggjm4mbp4o75pn6sbasst72r',
        'app.looplearn.live': '74ggjm4mbp4o75pn6sbasst72r',
        'dev.loopsafe.live': 'bm9qmekkjfcmb8187eku6u5dt',
        'beta.loopsafe.live': '49rbv11p1d1mq33l1dubatgqls',
        'app.loopsafe.live': '7c90nrjc90h5esmdnu19g1hqa7'
    }
};
export const UserPoolId = cognito_mappings.user_pool_id[subdomain];
export const ClientId = cognito_mappings.client_id[subdomain];
export const apinewurl = ( subdomain.includes('loopsafe') ) ? 'https://api.'+ getAliasFromSubDomain(subdomain, 'prod') +'.loopsafe.live/webV1' : 'https://j7wii6a6u5.execute-api.ap-southeast-2.amazonaws.com/' + getAliasFromSubDomain(subdomain);

/* START V2 */
export const baseURL = ( subdomain.includes('loopsafe') ) ? 'https://api.'+ getAliasFromSubDomain(subdomain, 'prod') +'.loopsafe.live/webV2' : 'https://467xh4opj1.execute-api.ap-southeast-2.amazonaws.com/' + getAliasFromSubDomain(subdomain, 'prod');
/* END V2 */

export const getUrl = window.location;
export const basepath = 'https://' + subdomain + '/';
export const basepathAdmin = 'https://' + subdomain + '/admin/';

export const authDomain = ( subdomain.includes('loopsafe') ) ? `auth.${getAliasFromSubDomain(subdomain, 'prod')}.loopsafe.live`: 'auth.looplearn.live';
export const loginUrl = `https://${authDomain}/login?response_type=code&client_id=${ClientId}&redirect_uri=https://${subdomain}/oauth.html&state=STATE&scope=aws.cognito.signin.user.admin+email+openid+phone+profile`;
export const logoutUrl = `https://${authDomain}/logout?response_type=code&client_id=${ClientId}&redirect_uri=https://${subdomain}/oauth.html&state=STATE&scope=aws.cognito.signin.user.admin+email+openid+phone+profile`
export const cCode = cookies.get('customer_code');
export const cRegion = cookies.get('region');
export const cTimezone = cookies.get('timezone');
export const ACCESS_TOKEN = cookies.get('access_token');
export const AUTH_TOKEN = cookies.get('authToken');
//export const cTimeZoneOffset = cookies.get('gmtAdjustment');
export const cTimeZoneOffset = cookies.get('gmtAdjustment') ? cookies.get('gmtAdjustment') : '+11:00'; // JUST FOR DEV PURPOSES

export const CUSTOMER_TYPE = cookies.get('customerType') ? cookies.get('customerType') : 'education';
export const MEMBER_NAME = cookies.get('memberName') ? cookies.get('memberName').charAt(0).toUpperCase() + cookies.get('memberName').toLowerCase().slice(1) : 'Users';
export const SITE_NAME = cookies.get('siteName') ? cookies.get('siteName').charAt(0).toUpperCase() + cookies.get('siteName').toLowerCase().slice(1) : 'Site';
export const MEMBER_FIELD = cookies.get('memberField') ? cookies.get('memberField') : 'Users';

/* IMAGE PLACEHOLDERS */
export const nif = 'images/nif.png';
export const nuf = 'images/no-user.jpeg';

function getDomain(url, subdomain) {
    subdomain = subdomain || false;

    url = url.replace(/(https?:\/\/)?(www.)?/i, '');

    if (!subdomain) {
        url = url.split('.');

        url = url.slice(url.length - 2).join('.');
    }

    if (url.indexOf('/') !== -1) {
        return url.split('/')[0];
    }

    return url;
}

function getAliasFromSubDomain(subdomain, environment = "default") {

    const subDomainArr = subdomain.split('.');

    const alias = subDomainArr[0];

    // if (alias === 'app') {
    //     return environment;
    // }

    return alias;
}


export const ipAddressRegx = new RegExp('^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$');

export const config = {
    'validation': 'All fields with * are required',
    'time_out': 1500,
    'redirect_time_out': 1500,
    'ipAddressRegx': ipAddressRegx,
    'isodate_format': 'YYYY-MM-DDTHH:mm:ssZ',
    'date_format': 'DD MMM YYYY',
    'default_timezone_offset': '+10:00',
    'longer_time_out':4000,
};

export const routesConfig = {
    'member_attendance': ['/students_results.html'],
    'location_attendance': ['/students_room_rolls.html'],
    'unverified_people': ['/unidentified_people.html'],
    'sign_in_logs': ['/visitor_attendance.html', '/sign_in_user.html', '/visit_details.html'],
    'staff_attendance': ['/staff_attendance.html'],
    'visitor_schedule': ['/visitor_schedule.html', '/schedule_visit.html', '/edit_scheduled_visit.html', '/add_user.html', '/edit_user.html', '/edit_visitor.html'],
    'companies': ['/companies.html', '/add_company.html', '/add_company_group.html', '/edit_company.html', '/edit_company_group.html'],
    'people_list': ['/people.html', '/edit_user.html', '/add_user.html', '/add_student.html', '/bulk_edit_users.html'],
    'survey_results': ['/survey_results.html'],
    'setup': [
        '/setup.html',
        '/rooms.html',
        '/edit_room.html',
        '/add_room.html',
        '/periods.html',
        '/add_period.html',
        '/edit_period.html',
        '/devices.html',
        '/bulk_edit_devices.html',
        '/edit_device_v1.html',
        '/edit_sensor.html',
        '/visitor_management.html',
        '/edit_visitor_type.html',
        '/importer.html',
        '/templates.html',
        '/add_member_group.html',
        '/edit_member_group.html',
        '/add_staff_group.html',
        '/edit_staff_group.html',
        '/edit_visitor.html',
        '/sites.html',
        '/add_site.html',
        '/edit_site.html',
        '/integriti_setup.html',
        '/api_settings.html'
    ],
}

export const tzOffset = cTimeZoneOffset ? cTimeZoneOffset : config.default_timezone_offset;



// html/js/v2/constants.js

/* BASEURL */
export const apiURL = 'https://467xh4opj1.execute-api.ap-southeast-2.amazonaws.com/dev/';

/* API URLS */
export const peopleAPI = '/people';
export const userLoginAPI = '/loginuser';
export const congnitoIdentityAPI = '/cognitoidentity';
export const unassignedDevicesAPI = '/unassigneddevices';
export const visitorAttendanceAPI = '/visitorattendance';
export const exportSignInLogsPath = '/visitorattendance/export';
export const visitorScheduleAPI = '/visitorschedule';
export const collectionImagesAPI = '/collectionimages';
export const unidentifiedPeopleAPI = '/unidentifiedpeople';
export const customerSettingsAPI = '/customersettings';
export const peoplegroupauditAPI = '/peoplegroupaudit';
export const deviceGroupsAPI = '/devicegroups';
export const devicesAPI ='/devices';
export const versionsAPI = '/versions';
export const peopleGroupsAPI = '/peoplegroups';
export const visitorTypesAPI = '/visitortypes';
export const memberGroupsAPI = '/membergroups';
export const staffGroupsAPI = '/staffgroups';
export const imagesToDeleteAPI = '/imagestodelete';
export const companiesAPI = '/companies';
export const companyGroupAPI = '/companygroups';
export const apikeyAPI = '/apikeys';

/* QUERY STRING PARAMS */
export const queryParams = {
    users: ['user_type'],
    people: ['year_level', 'home_level', 'person_type', 'visitor_type', 'return_only', 'site_id'],
    unassigned_devices: ['device_type'],
    visitor_attendance: ['time_in', 'people_id', 'stillhere', 'person_type'],
    visitor_schedule: ['timein', 'email'],
    unidentified_people: ['date_added', 'room_name', 'site_id'],
    people_group_audit: ['created_at', 'updated_at', 'group_name', 'group_type'],
    devices : ['device_type','is_activated','return_only'],
    images_to_delete : ['people_id'],
    company : []
};

/* COMMON VARIABLES */
export const commonHeaders = {
    Authorization: cookies.get('authToken') ? cookies.get('authToken') : AUTH_TOKEN,
    'Content-Type': 'application/json',
};

export const headersSuperAdmin = {
    Authorization: AUTH_TOKEN,
    'Content-Type': 'application/json',
};


/* REGULAR EXPRESSION CONSTANTS */
export const URL_REGEX = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PEOPLE_ID_REGEX = /^[a-zA-Z0-9_.\-:]+$/;
export const USERNAME_REGEX = /^\S*$/;
// const MOBILE_REGEX= /^\+?[1-9]\d{1,14}$/; This one failed in some cases. So using below one. To be reviewed by Krunal
export const MOBILE_REGEX = /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/


/*PERSON TYPE SCHEMA CONSTANTS*/
export const DEFAULT_STAFF_GROUP_SCHEMA = {"staff_custom_details":{},"screening_questions":{"questionnaire":[],"failure_message":"Please see a staff member to discuss your screening question responses."},"requirement_checklist":[],"documents":[],"name":"","message":"Please see a staff member to discuss your screening question responses.","email_config":{"to_staff":{"blocked_sign_in_screening_receipients":[],"temperature_blocked_recipients":[],"temperature_blocked":false,"document_checklist_blocked":false,"document_checklist_blocked_recipients":[],"blocked_sign_in_screening":false}},"sms_config":{"to_staff":{"denied_entry_alert":false,"denied_entry_alert_receipients":[]}}};
// export const LICENSES = new Map([
//     ["PRO", { id: 'loopkiosk_pro', name: 'LoopKiosk Pro'}],
//     ["STANDALONE", { id: 'loopkiosk_standalone', name: 'LoopKiosk Standalone'}]
// ]);

export const LICENSES = {
    'PRO': { id: 'loopkiosk_pro', name: 'LoopKiosk Pro'},
    'STANDALONE': { id: 'loopkiosk_standalone', name: 'LoopKiosk Standalone'}
}